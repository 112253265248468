import Axios from 'axios'
import { ethers } from 'ethers'
import { arrayify, concat, hexlify, joinSignature, toUtf8Bytes } from 'ethers/lib/utils'

export const initResponse = (response) => {
    if (!response.data?.statusCode) {
        response.data = { ok: false, statusCode: 404 }
    }
    // init error
    if (response.data.statusCode === 200) {
        response.data.ok = true
    } else {
        response.data.ok = false
    }
    return response.data
}


const axios = Axios.create({
    // baseURL: 'http://localhost:3030/',
    // timeout: 25000,
    // headers: {},
})

// interceptors https://axios-http.com/zh/docs/interceptors
axios.interceptors.request.use(
    function (config) {
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

async function post(urlSuffix, wallet, params = {}) {
    let message = concat([toUtf8Bytes(urlSuffix), toUtf8Bytes(JSON.stringify(params))])
    let hash = ethers.utils.sha256(message)
    let signature = joinSignature(wallet._signingKey().signDigest(hash))
    signature = signature.substring(0, signature.length - 2)
    console.log("signature:", signature);
    const res = await axios({
        url: urlSuffix, method: 'post', data: params, headers: {
            "signature": signature,
        }
    })
    return res.data;
}


export async function handleGenerateKey(worker, email, userId, secretKey) {
    if (localStorage.getItem("ecdsaContext") != null) {
        let { address } = JSON.parse(localStorage.getItem("ecdsaContext"));
        return address
    }

    console.log(secretKey, secretKey.length)

    const wallet = new ethers.Wallet(secretKey)

    if (email.length < 5) {
        throw new Error("email too short")
    }

    let userIdNum = parseInt(userId)

    console.log("begin to generate key");
    console.log("userId: ", userIdNum);
    console.log("email: ", email)

    let hash = await worker.keccak256([1, 2, 34, 35]);
    console.log("hash", hash)


    let path = '/create_keyId'
    let keyId = await post(path, wallet)
    console.log("keyId: ", keyId);

    path = '/start_keygen/' + keyId + "/" + userIdNum + "/" + email
    let res = await post(path, wallet);

    console.log('sessionId', res.sessionId)
    console.log('msg1', res.msg)

    const [context2, p2FirstMsg] = await worker.li17_p2_key_gen1(res.msg)

    console.log('context2', context2)
    console.log('p2FirstMsg', p2FirstMsg)

    let msg = {
        tssMsg: p2FirstMsg
    }

    path = '/keygen/' + res.sessionId
    res = await post(path, wallet, msg);

    console.log('msg2', res.msg)

    const [signContext2, pubkey] = await worker.li17_p2_key_gen2(context2, res.msg)
    console.log('signContext2', signContext2)
    console.log('pubkey', pubkey)

    let addr = ethers.utils.computeAddress(pubkey.point)

    console.log('addr', addr)

    path = "/finish_keygen/" + res.sessionId
    await post(path, wallet);


    localStorage.setItem("ecdsaContext", JSON.stringify({ "keyId": keyId, "userId": userIdNum, "email": email, "signContext": signContext2, "pubkey": pubkey, "address": addr }))

    return addr;
}

export async function handleSignMessage(worker, message, secretKey) {
    console.log("begin to sign message");

    const wallet = new ethers.Wallet(secretKey)

    let hash = await worker.keccak256([1, 2, 34, 35]);
    console.log("hash", hash)

    let { keyId, signContext, pubkey, address } = JSON.parse(localStorage.getItem("ecdsaContext"));

    const message_hash = ethers.utils.hashMessage(message)

    console.log("message_hash", message_hash)
    console.log("keyId", keyId)
    console.log("signContext", signContext)
    console.log("pubkey", pubkey)
    console.log("address", address)

    const msg_hash = Array.from(arrayify(message_hash))

    console.log("msg_hash", msg_hash, "len", msg_hash.length)

    const [context1, message1] = await worker.li17_p2_sign1(signContext, msg_hash)

    console.log("context1", context1)
    console.log("message1", message1)

    let msg = {
        tssMsg: message1,
        value: hexlify(toUtf8Bytes(message))
    }

    let path = '/start_sign/' + keyId
    let res1 = await post(path, wallet, msg);

    console.log("res1", res1)

    const [partialSig, message2] = await worker.li17_p2_sign2(context1, res1.msg)

    console.log("partialSig", partialSig)
    console.log("message2", message2)


    msg = {
        tssMsg: [partialSig, message2]
    }

    path = '/sign/' + res1.sessionId
    let res2 = await post(path, wallet, msg);

    console.log("res2", res2)

    // TODO: request server to get key
    return res2.msg;
}

export async function handleRefreshMessage(worker, secretKey) {
    console.log("begin to refresh message");

    const wallet = new ethers.Wallet(secretKey)

    let { keyId, signContext, pubkey, address } = JSON.parse(localStorage.getItem("ecdsaContext"));

    console.log("keyId", keyId)
    console.log("signContext", signContext)
    console.log("pubkey", pubkey)
    console.log("address", address)

    let path = '/start_refresh/' + keyId
    let res1 = await post(path, wallet);

    console.log("res1", res1)
    const [signContextTmp, x3Secret, message1] = await worker.li17_p2_refresh1(signContext, res1.msg)

    console.log("message1", message1)
    console.log("x3Secret", x3Secret)

    let msg = {
        tssMsg: message1
    };

    path = '/refresh/' + res1.sessionId
    let res2 = await post(path, wallet, msg);

    console.log("res2", res2)

    path = "/finish_refresh/" + res1.sessionId
    await post(path, wallet);

    let addr = ethers.utils.computeAddress(signContextTmp.public.point);
    localStorage.setItem("ecdsaContext", JSON.stringify({ "keyId": keyId, "signContext": signContextTmp, "pubkey": signContextTmp.public, "address": addr }))

    // TODO: request server to get key
    return x3Secret;
}
