import { h, ref } from "vue";
import { ElMessage } from "element-plus";
import { handleGenerateKey, handleRefreshMessage, handleSignMessage } from "@/services/mpc";
import { worker } from "@/worker-provider";
import { ethers } from "ethers";
import { hexlify } from "ethers/lib/utils";

export const useMpcDemo = () => {
    const address = ref("");
    const recoveredAddress = ref("");
    const message = ref("");
    const email = ref("")
    const userId = ref("")
    const signature = ref("");
    const keyShare3 = ref("");
    const secretKey = ref("");

    // generate key
    const generateKey = async () => {
        ElMessage({
            message: h("p", null, [
                h("i", { style: "color: teal" }, 'generateKey clicked'),
            ]),
        });
        console.log('generateKey clicked')

        console.time("keygen")
        const addr = await handleGenerateKey(worker, email.value, userId.value, secretKey.value)
        console.timeEnd("keygen")

        address.value = addr
    };

    // sign message
    const signMessage = async () => {
        ElMessage({
            message: h("p", null, [
                h("span", null, "Message = "),
                h("i", { style: "color: teal" }, message.value),
            ]),
        });

        console.log('sign clicked')


        console.time("sign")
        const sig = await handleSignMessage(worker, message.value, secretKey.value)
        console.timeEnd("sign")

        const sigArray = ethers.utils.concat([sig.r, sig.s, sig.recid])

        signature.value = hexlify(sigArray)
    };

    // recover address
    const recoverAddress = () => {
        ElMessage({
            message: h("p", null, [
                h("i", { style: "color: teal" }, 'recoverAdress clicked'),
            ]),
        });


        const recoveredAddr = ethers.utils.recoverAddress(ethers.utils.hashMessage(message.value), signature.value)
        recoveredAddress.value = recoveredAddr
    };

    const refreshKey = async () => {
        ElMessage({
            message: h("p", null, [
                h("i", { style: "color: teal" }, 'refresh clicked'),
            ]),
        });

        console.log('refresh clicked')
        console.time("refresh")
        const x3Secret = await handleRefreshMessage(worker, secretKey.value)
        console.timeEnd("refresh")

        keyShare3.value = x3Secret
    };

    return {
        address,
        recoveredAddress,
        message,
        userId,
        email,
        signature,
        keyShare3,
        generateKey,
        signMessage,
        recoverAddress,
        refreshKey,
        secretKey
    }

}