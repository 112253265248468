<template>
  <div class="hello">
    <h1>TWO PARTY ECDSA DEMO</h1>

    <div>
      <el-row>
        <el-input v-model="userId" placeholder="Please input userId(i64) here"></el-input>
      </el-row>
      <el-row>
        <el-input v-model="email" placeholder="Please input email here"></el-input>
      </el-row>

      <el-button @click="generateKey">Generate Key</el-button>
    </div>

    <el-row>
      <el-input v-model="secretKey" placeholder="Please input auth secret key(Hex)"></el-input>
    </el-row>

    <el-row>
      <p>pubkey: {{ address }}</p>
    </el-row>
    <el-row>
      <p> x3: {{ keyShare3 }}</p>
    </el-row>

    <el-divider></el-divider>

    <div>
      <el-row>
        <el-input :rows="2" v-model="message" type="textarea" placeholder="Please input message here"></el-input>
      </el-row>
      <el-row>
        <p>signature: {{ signature }}</p>
      </el-row>

      <el-row>
        <p>recovered Address: {{ recoveredAddress }}</p>
      </el-row>

      <el-row>
        <el-button @click="signMessage">Sign Message</el-button>
        <el-button @click="recoverAddress">Recover Address</el-button>
      </el-row>

      <el-row>
        <el-button @click="refreshKey">Refresh Key</el-button>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import { useMpcDemo } from "../composable/useMpcDemo";

const {
  address,
  message,
  email,
  userId,
  signature,
  recoveredAddress,
  keyShare3,
  generateKey,
  signMessage,
  refreshKey,
  recoverAddress,
  secretKey
} = useMpcDemo();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
