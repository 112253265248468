<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="TWO PARTY ECDSA DEMO" />
</template>

<script setup>
import HelloWorld from './components/HelloWorld.vue'
import { webWorker } from './web-worker.js'
import VConsole from "vconsole"
import { initPlugin } from 'vue-vconsole-devtools'
// export default {
//   name: 'App',
//   components: {
//     HelloWorld
//   },
// }

initPlugin(new VConsole())

if (window.Worker) {
  let workerReady = false;

  const onWorkerReady = (msg) => {
    if (msg.data.ready) {
      workerReady = true;
      console.log('worker ready')
      webWorker.removeEventListener("message", onWorkerReady);
    }
  };

  webWorker.addEventListener("message", onWorkerReady);

  if (!workerReady) {
    console.log('worker not ready')
  } else {
    console.log('worker ready')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
